const w = window;
const d = document;
const b = 'body';
const viewport = 'html, body';

const throttleTime = 150; // Event firing every 'n' seconds
const debounceTime = 30;  // Event firing after 'n' seconds

d.ontouchstart = () => {};

const scrollAct = '.js-scroll-act';
const scrollBrd = 400;

const navFxd = '.mn-nav_bdy.-fxd';

// console.log($(w).innerHeight());
// console.log(w.screen.height);
// console.log(w.innerHeight);

$.get('https://ipinfo.io', function(res) {
	const getIpAddr = res.ip;
	
	copyProt(getIpAddr);
}, 'json');

function copyProt(val) {
	const chkDev = '203.152.221.19' == val || '106.178.118.102' == val;
	
	if(!chkDev) {
		d.oncontextmenu = function() {return false;};
		$(d).on('keydown', function(e) {
			const keyCode = e.keyCode;
			const dsblKey = keyCode === 80 || keyCode === 83 || keyCode === 17 || keyCode === 91;
			
			if(dsblKey) {
				return false;
			}
		});
	}
}

$(w).on('load', function() {
	$(b).addClass('is-load');
	cloneNav();
});

const userAgent = navigator.userAgent.toLowerCase();
// console.log(userAgent);

/*
 * user agent for mobile
 */
const userAgentIphone = userAgent.indexOf('iphone') > -1 && userAgent.indexOf('ipod') == -1;
const userAgentIpad = userAgent.indexOf('ipad') > -1 || userAgent.indexOf('macintosh') > -1 && 'ontouchend' in d;
const userAgentAndroid = userAgent.indexOf('android') > -1 && userAgent.indexOf('mobile') > -1;
const userAgentMobile = userAgentIphone || userAgentIpad || userAgentAndroid;

let MobileFlg;

if(userAgentMobile) {
	MobileFlg = true;
	
	$(b).addClass('ua-mbl');
}

/*
 * user agent for IE
 */
const userAgentIe = userAgent.indexOf('msie') > -1 || userAgent.indexOf('trident') > -1 || userAgent.indexOf('edge') > -1;
const userAgentIeLgcy = userAgent.indexOf('msie') > -1 || userAgent.indexOf('trident') > -1;

if(userAgentIeLgcy) {
	$(b).addClass('ua-ie ua-ie-lgcy');
} else if(userAgentIe) {
	$(b).addClass('ua-ie');
}

/*
 * user agent for macOs
 */
const userAgentMacOsMbl = userAgent.indexOf('mac os') > -1;
const userAgentMacOsSfr = userAgent.indexOf('mac os') > -1 && userAgent.indexOf('chrome') == -1 && userAgent.indexOf('firefox') == -1;
const userAgentMacOs = MobileFlg && userAgentMacOsMbl || userAgentMacOsSfr;

if(userAgentMacOs) {
	$(b).addClass('ua-mac-os');
}

/*
 * nav
 */
function cloneNav() {
	const cloneNav = '.mn-nav_bdy';
	
	$(cloneNav).clone(true).addClass('-add-bg -fxd').insertAfter(cloneNav);
}

/*
 * smooth scroll
 */
const smsc = '.js-smsc a, a.js-smsc';
const speed = 380;

$(smsc).on('click', function() {
	const href = $(this).attr('href');
	const target = $(href == '#' || href == '' ? 'html' : href);
	const position = target.offset().top;
	const scrollBak = Math.ceil($('.mn-nav').outerHeight(true));
	const easing = 'easeInSine';
	
	$(viewport).animate({scrollTop: position - scrollBak}, speed, easing);
	
	if($(this).hasClass('js-tr-trg') && !$(href).hasClass('is-opn')) {
		$(href).trigger('click');
	}
	return false;
});

setTimeout(function() {
	$(w).on('load scroll', $.throttle(throttleTime,
		function() {
			if($(this).scrollTop() > scrollBrd) {
				$(scrollAct).addClass('is-shw');
			} else {
				$(scrollAct).removeClass('is-shw');
			}
		})
	);
}, 300);

/*
	$(d).on('click','.pagetop',function(){
		$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
			if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
				$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
			}
		}).animate({ scrollTop: 0 },500,'linear');
		return false;
	}).on('click','.smsc',function(){
		var href = $(this).attr('href').replace(/^.*#/, "#"),
			pos = $(href == "#" || href == "" ? viewport : href ).offset().top;
		$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
			if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
				$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
			}
		}).animate({ scrollTop: pos },300,'linear');
		return false;
	});
*/

/*
 * window scroll
 */
let winScrlNum = 0;

$(w).on('load scroll', $.throttle(throttleTime, winScrlFnc));

function winScrlFnc() {
	const winCurScrlNum = $(w).scrollTop();
	
	if(winCurScrlNum < scrollBrd || winCurScrlNum > winScrlNum) {
		$(navFxd).removeClass('is-shw');
	} else if(winCurScrlNum < winScrlNum) {
		$(navFxd).addClass('is-shw');
	}
	
	winScrlNum = winCurScrlNum;
}

/*
 * nav
 */
const navAcrdTrg = '.js-nav-acrd-trg';
const navAcrdCnt = '.js-nav-acrd-cnt';
const acrdDur = 300;

$(navAcrdTrg).on('click', function() {
	const easing = 'easeOutQuint';
	
	if(!$(this).hasClass('is-opn')) {
		$(this).addClass('is-opn')
			   .next(navAcrdCnt).stop().slideDown(acrdDur, easing);
	} else {
		$(this).removeClass('is-opn')
			   .next(navAcrdCnt).stop().slideUp(acrdDur, easing, function() {
			$(this).removeAttr('style');
		});
	}
	
	return false;
});

/*
 * emergence
 */
emergence.init({
	container: w,
	reset: true,
	handheld: true,
	throttle: 250,
	elemCushion: 0.01,
	callback: function(element, state) {
		if(state === 'visible') {
			$(element).addClass('is-shw');
			shwCrFlg();
		} else if(state === 'reset') {
			$(element).removeClass('is-shw');
			shwCrFlg();
		}
	}
});

function shwCrFlg() {
	if($('.cr').hasClass('is-shw')) {
		$('.mn-ftr').addClass('is-actv');
	} else {
		$('.mn-ftr').removeClass('is-actv');
	}
}

/*
 * from
 */
const btnAgree = '.js-btn-agree';
const btnFrm = '.js-btn-frm';

$(btnAgree).on('click touchstart', function() {
	$(this).toggleClass('is-actv').next(btnFrm).toggleClass('utl-hdn');
	
	return false;
});

/*
 * modal
 */
const mdlWin = '.js-mdl';
const opnMdl = '.js-opn-mdl';
const clsMdl = '.js-cls-mdl';

$(mdlWin).each(function() {
	if(!$(this).hasClass('nav-mdl')) {
		$(this).append('<div class="mdl_cls utl-ovr js-cls-mdl"></div>');
		$(this).append('<div class="mdl_ovrly js-cls-mdl"></div>');
	}
});

$(opnMdl).on('click', function() {
	const getOpnMdlId = $(this).attr('href');
	
	$(getOpnMdlId).addClass('is-opn');
	return false;
});
$(clsMdl).on('click', function() {
	const hasYtb = $(this).parents(mdlWin).find('.ytb');
	
	$(this).parents(mdlWin).removeClass('is-opn');
	
	if(hasYtb.length) {
		const getYtbPlyrId = hasYtb.find('.ytb_mov').attr('id');
		
		chgMovId(getYtbPlyrId);
	}
});

/*
 * accordion
 */
const prmAcrdTrg = '.prm-acrd_trg.js-acrd-trg';
const prmAcrdCnt = '.prm-acrd_cnt.js-acrd-cnt';

$(prmAcrdTrg).on('click', function() {
	const easing = 'easeOutQuint';
	
	if(!$(this).hasClass('is-opn')) {
		$(this).addClass('is-opn')
			   .next(prmAcrdCnt).stop().slideDown(acrdDur, easing);
	} else {
		$(this).removeClass('is-opn')
			   .next(prmAcrdCnt).stop().slideUp(acrdDur, easing, function() {
			$(this).removeAttr('style');
		});
	}
	
	return false;
});

/*
 * tab
 */
const tabTrg = '.js-tab-trg';
const tabCnt = '.js-tab-cnt';

$(tabTrg).on('click touchstart', function() {
	const getShwTabId = $(this).attr('href');
	
	$(tabTrg).parent().removeClass('is-actv');
	$(this).parent().addClass('is-actv');
	$(getShwTabId).addClass('is-shw').siblings(tabCnt).removeClass('is-shw');
	return false;
});

/*
 * scroll hint
 */
new ScrollHint('.js-scroll-hint', {
	i18n: {
		scrollable: 'スクロールできます'
	}
});

/*
 * change svg style
 */
deSVG('.js-de-svg', true);

/*
 * if only resize horizontal
 */
let winReSizeWidth = 0;

$(w).on('load resize', $.throttle(throttleTime, reSizeHr));

function reSizeHr() {
	const winCurWidth = $(w).width();
	
	if(winCurWidth != winReSizeWidth) {
		// console.log(true);
	}
	
	winReSizeWidth = winCurWidth;
}
